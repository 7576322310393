class FloatingPointField extends DefaultFormField {
    constructor(element) {
      super(element);

      this.determineElements();
      this.attachHandlers();
    }

    attachHandlers() {
      if (this.input !== null) {
        this.addValueChangedHandler(this.input);
        this.addBlurHandler(this.input);
      }
    }

    checkInput() {
      super.checkInput();

      const value = this.getValue();

      if (value !== null && !/^-?\d+(\.\d+)?$/.test(value))
        this.addProblem(this.element.dataset.InvalidValueMessage);
    }

    determineElements() {
      this.input = this.element;
    }
  }

  interactivityRegistration.register("FloatingPointField", function (element) { return new FloatingPointField(element); });
