const PeriodSelectionMode = new Enumeration(["Start", "End"]);

class PeriodSelectorPage {
  constructor(periodStart, periodEnd, dayLabels, year, month, period) {
    this.periodStart = periodStart;
    this.periodEnd = periodEnd;
    this.period = period;

    this.dayLabels = dayLabels;
    this.year = year;
    this.month = month;

    this.daysInMonth = daysInMonth(year, month);
    this.startDay = this.determineStartDay();
    this.endDay = this.determineEndDay();
    this.referenceDate = new Date();
    this.focusedIndex = this.startDay;
  }

  determineStartDay() {
    let day = new Date(this.year, this.month, 1).getDay() + 6 % 7;

    if (day < 3)
      day = day + 7;

    if (day > 10)
      day = day - 7;

    return day;
  }

  determineEndDay() {
    return this.startDay + this.daysInMonth - 1;
  }

  daysArray() {
    const array = new Array(42);
    const start = this.startDay;

    const date = new Date(this.year, this.month, 1);
    date.setDate(date.getDate() - start);

    for (let index = 0; index < array.length; index++) {
      array[index] = new Date(date.valueOf());
      date.setDate(date.getDate() + 1);
    }

    return array;
  }

  changeFocus(delta) {
    this.focusIndex(this.focusedIndex + delta);
  }

  focus(previousIndex) {
    this.buttons[previousIndex].tabIndex = -1;

    const button = this.buttons[this.focusedIndex];
    button.tabIndex = 0;
    button.focus();
  }

  focusIndex(index) {
    const previousIndex = this.focusedIndex;

    this.focusedIndex = index;
    this.focus(previousIndex);
  }

  focusLast() {
    this.focusIndex(this.startDay + this.daysInMonth - 1);
  }

  focusFirst() {
    this.focusIndex(this.startDay);
  }

  handleDateClick(date) {
    return () => this.setDate(date);
  }

  headerRow() {
    const row = new HTMLTableRow();
    row.addArrayAsHeaderCells(this.dayLabels);

    return row.render();
  }

  isToday(date) {
    return this.equalDates(this.referenceDate, date);
  }

  equalDates(left, right) {
    return left.getFullYear() === right.getFullYear() && left.getMonth() === right.getMonth() && left.getDate() === right.getDate();
  }

  render(table) {
    this.buttons = new Array();
    table.addHeaderRow(this.headerRow());

    let index = 0;
    const daysArray = this.daysArray();

    while (index < daysArray.length) {
      const row = new HTMLTableRow();

      for (let weekDay = 0; weekDay < 7; weekDay++) {
        const date = daysArray[index];

        const label = document.createElement("span");
        label.innerText = date.getDate();

        const button = document.createElement("button");
        button.appendChild(label);
        button.onclick = this.handleDateClick(date);
        button.tabIndex = -1;

        this.buttons.push(button);

        const cell = document.createElement("td");
        cell.appendChild(button);

        if (date.getMonth() !== this.month)
          cell.classList.add("OtherMonth");
        else {
          if (this.period.start !== null && date.getTime() === this.period.start.getTime())
            cell.classList.add("PeriodStart");

          if (this.period.end !== null && date.getTime() === this.period.end.getTime())
            cell.classList.add("PeriodEnd");

          if (this.period.start !== null && this.period.end !== null && date.getTime() > this.period.start.getTime() && date.getTime() < this.period.end.getTime())
            cell.classList.add("InPeriod");
        }

        if (this.isToday(date))
          cell.classList.add("Today");

        row.addTableData(cell);
        index++;
      }

      table.addRow(row.render());
    }

    return table.render();
  }

  setDate(date) {
    if (this.period.mode === PeriodSelectionMode.Start) {
      this.setValue(this.periodStart, date);

      if (date > this.period.end)
        this.setValue(this.periodEnd, null);

      this.period.mode = PeriodSelectionMode.End;
    }
    else if (this.period.mode === PeriodSelectionMode.End) {
      if (date < this.period.start)
        this.setValue(this.periodStart, date);
      else {
        this.setValue(this.periodEnd, date);
        this.period.callback();
      }
    }
  }

  setValue(field, date) {
    if (date !== null)
      field.value = this.valueToText(date);
    else
      field.value = '';

    field.dispatchEvent(new Event("input"));
    field.dispatchEvent(new Event("change"));
  }
}

class PeriodSelector {
  constructor(periodStart, periodEnd, monthLabels, dayLabels, todayLabel, callback) {
    this.periodStart = periodStart;
    this.periodEnd = periodEnd;

    this.monthLabels = monthLabels;
    this.dayLabels = dayLabels;
    this.todayLabel = todayLabel;
    this.element = document.createElement("div");
    this.expanded = new HtmlClassSwitch(this.element, "Expanded");

    this.period = {
      start: this.parseValue(this.periodStart.value),
      end: this.parseValue(this.periodEnd.value),
      mode: PeriodSelectionMode.Start,
      callback: callback
    }

    if (this.period.start !== null)
      this.referenceDate = new Date(this.period.start.getTime());
    else if (this.period.end !== null)
      this.referenceDate = new Date(this.period.end.getTime());
    else
      this.referenceDate = new Date();

    this.create();
    this.setPeriodSelectionMode(this.period.mode);
  }

  changeMonth(index) {
    const date = this.referenceDate.getDate();
    this.referenceDate.setMonth(this.referenceDate.getMonth() + index);

    if (this.referenceDate.getDate() < date) {
      this.referenceDate.setDate(0);
    }
  }

  create() {
    this.element.className = "DateSelector";
    this.element.tabIndex = "0";

    this.currentNode = this.refreshPage();
    this.element.appendChild(this.currentNode);

    this.periodStart.addEventListener(
      "input",
      (event) => {
        const value = this.parseValue(this.periodStart.value);

        this.period.start = value;
        this.refresh();
      }
    );

    this.periodEnd.addEventListener(
      "input",
      (event) => {
        const value = this.parseValue(this.periodEnd.value);

        this.period.end = value;
        this.refresh();
      }
    );
  }

  createKeyHandler() {
    return (event) => this.handleKey(event);
  }

  handleClickChangeMonthEvent(link, index) {
    return (event) => {
      this.changeMonth(index);
      this.refresh();

      getEvent(event).stopHandling();
    }
  }

  handleKey(event) {
    if (event.code === "ArrowLeft") {
      if (this.page.focusedIndex > this.page.startDay)
        this.page.changeFocus(-1);
      else {
        this.changeMonth(-1);
        this.refresh();
        this.page.focusLast();
      }

      event.preventDefault();
    }
    else if (event.code === "ArrowRight") {
      if (this.page.focusedIndex < this.page.endDay)
        this.page.changeFocus(1);
      else {
        this.changeMonth(1);
        this.refresh();
        this.page.focusFirst();
      }

      event.preventDefault();
    }
    else if (event.code === "ArrowUp") {
      if (this.page.focusedIndex >= this.page.startDay + 7)
        this.page.changeFocus(-7);
      else {
        const delta = this.page.startDay - (this.page.focusedIndex - 7);

        this.changeMonth(-1);
        this.refresh();
        this.page.changeFocus(this.page.daysInMonth - delta);
      }

      event.preventDefault();
    }
    else if (event.code === "ArrowDown") {
      if (this.page.focusedIndex <= this.page.endDay - 7)
        this.page.changeFocus(7);
      else {
        const delta = this.page.focusedIndex - this.page.endDay + 7;

        this.changeMonth(1);
        this.refresh();
        this.page.changeFocus(delta - 1);
      }

      event.preventDefault();
    }
    else if (event.code === "Home") {
      this.page.focusFirst();
      event.preventDefault();
    }
    else if (event.code === "End") {
      this.page.focusLast();
      event.preventDefault();
    }
  }

  header() {
    const fastBackward = document.createElement("button");
    const backward = document.createElement("button");
    const caption = document.createElement("span");
    const forward = document.createElement("button");
    const fastForward = document.createElement("button");

    fastBackward.innerHTML = "&lt;&lt;";
    fastBackward.onclick = this.handleClickChangeMonthEvent(fastBackward, -12);

    backward.innerHTML = "&lt;";
    backward.onclick = this.handleClickChangeMonthEvent(backward, -1);

    forward.innerHTML = "&gt;";
    forward.onclick = this.handleClickChangeMonthEvent(forward, 1);

    fastForward.innerHTML = "&gt;&gt;";
    fastForward.onclick = this.handleClickChangeMonthEvent(fastForward, 12);

    caption.innerHTML = this.referenceDate.getFullYear() + " " + this.monthLabels[this.referenceDate.getMonth()];
    caption.className = "caption";

    const result = new HTMLTableRow();
    const fastBackwardTableData = new HTMLTableData();
    const backwardTableData = new HTMLTableData();
    const captionTableData = new HTMLTableData();
    const forwardTableData = new HTMLTableData();
    const fastForwardTableData = new HTMLTableData();

    captionTableData.setColSpan(3);
    fastBackwardTableData.appendChild(fastBackward);
    backwardTableData.appendChild(backward);
    captionTableData.appendChild(caption);
    forwardTableData.appendChild(forward);
    fastForwardTableData.appendChild(fastForward);

    result.addTableData(fastBackwardTableData.render());
    result.addTableData(backwardTableData.render());
    result.addTableData(captionTableData.render());
    result.addTableData(forwardTableData.render());
    result.addTableData(fastForwardTableData.render());

    return result.render();
  }

  refresh() {
    const newNode = this.refreshPage();

    this.element.replaceChild(newNode, this.currentNode);
    this.currentNode = newNode;
    this.page.focus(0);
  }

  refreshPage() {
    const table = new HTMLTable();
    table.addHeaderRow(this.header());
    table.tableBody.addEventListener("keydown", this.createKeyHandler());

    this.page = this.newPage();
    this.page.render(table);

    const result = document.createElement("div");
    result.appendChild(table.render());

    return result;
  }

  setPeriodSelectionMode(mode) {
    this.period.mode = mode;
    this.refresh();
  }
}

class PeriodField extends FormField {
  constructor(element) {
    super(element);

    if (this.mode === ControlMode.edit) {
      this.determineElements();
      this.initializeLabels();
      this.createSelector();
      this.attachEventHandlers();
    }
  }

  determineElements() {
    const query = new DomQuery(this.element);

    this.button = query.getChild(WithTagName("BUTTON"));
    this.selector = query.getChild(WithClass("PeriodSelector"));

    this.determineInputFields();

    this.input = document.createElement("input");
    this.input.setAttribute("type", "hidden");
    this.input.setAttribute("name", this.element.dataset.Name);
    this.input.value = this.determineValue();

    this.element.appendChild(this.input);

    this.submitButton = query.getDescendant(WithClass("Submit"));
    this.defaultPeriods = query.getDescendants(WithClass("DefaultPeriod"));
  }

  attachEventHandlers() {
    this.button.addEventListener("click", (event) => this.toggleSelector());
    this.submitButton.addEventListener("click", (event) => this.submit());

    this.start.addEventListener("change", (event) => this.updateValue());
    this.end.addEventListener("change", (event) => this.updateValue());

    for (const defaultPeriod of this.defaultPeriods) {
      defaultPeriod.addEventListener(
        "click",
        (event) => {
          this.start.value = defaultPeriod.dataset.PeriodStart;
          this.start.dispatchEvent(new Event("input"));

          this.end.value = defaultPeriod.dataset.PeriodEnd;
          this.end.dispatchEvent(new Event("input"));

          this.updateValue();
          this.submit();
        }
      );
    }

    this.addValueChangedHandler(this.input);
    this.addBlurHandler(this.start);
    this.addBlurHandler(this.end);
  }

  checkInput() {
    if ((this.startRequired || this.endRequired) && this.value === "")
      this.addProblem(this.element.dataset.EmptyValueMessage);

    if (this.value.length > 0) {
      const [start, end] = this.value.split(",");

      if (start.length > 0 && !this.isValid(start))
        this.addProblem(this.element.dataset.InvalidValueMessage);
      else if (start.length === 0 && this.startRequired)
        this.addProblem(this.element.dataset.StartRequiredMessage);

      if (end.length > 0 && !this.isValid(end))
        this.addProblem(this.element.dataset.InvalidValueMessage);
      else if (end.length === 0 && this.endRequired)
        this.addProblem(this.element.dataset.EndRequiredMessage);

      if (this.isValid(start) && this.isValid(end)) {
        const startDate = new Date(start);
        const endDate = new Date(end);

        if (startDate > endDate)
          this.addProblem(this.element.dataset.StartAfterEndMessage);
      }
    }
  }

  determineValue() {
    const renderer = new ValueTextRenderer();

    const start = this.start.value;
    const end = this.end.value;

    if (start.length > 0 || end.length > 0)
      return renderer.render(start) + ',' + renderer.render(end);
    else
      return "";
  }

  updateValue() {
    this.input.value = this.determineValue();
    this.input.dispatchEvent(new Event("change"));
  }

  getValue() {
    return this.input.value;
  }

  initializeLabels() {
    this.labels = new DomQuery(this.element).getDescendants(WithClass("Label"));

    this.monthLabels = new Array();
    this.dayLabels = new Array();

    let labelIndex = 0;

    for (let index = 1; index <= 12; index++) {
      this.monthLabels.push(this.labels[labelIndex].innerHTML);
      labelIndex++;
    }

    for (let index = 1; index <= 7; index++) {
      this.dayLabels.push(this.labels[labelIndex].innerHTML);
      labelIndex++;
    }

    this.todayLabel = this.labels[labelIndex].innerHTML;
  }

  submit() {
    this.toggleSelector();
    this.element.dispatchEvent(new Event("change"));
  }

  toggleSelector() {
    if (this.selector.classList.contains("Expanded")) {
      this.selector.classList.remove("Expanded");
      removeClickOutsideListener(this.clickOutsideListener);
    }
    else {
      this.selector.classList.add("Expanded");
      this.clickOutsideListener = connectClickOutsideListener(
        this.selector,
        () => this.toggleSelector()
      );
    }
  }

  get startRequired() {
    return this.element.dataset.StartRequired === "true";
  }

  get endRequired() {
    return this.element.dataset.EndRequired === "true";
  }
}
