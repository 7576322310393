class MultipleSelectButtons extends FormField {
  constructor(element) {
    super(element);

    if (this.mode === ControlMode.edit) {
      this.buttons = new Array();
      this.initialize();
      this.attachHandlers();

      if (this.buttons.length > 0)
        this.buttons[0].element.tabIndex = 0;
    }
  }

  addEventListener(event, handler) {
    this.input.addEventListener(event, handler);
  }

  attachHandlers() {
    for (const button of this.buttons) {
      button.element.addEventListener("click", this.createToggleButtonHandler(button));
      button.element.addEventListener("keydown", this.createKeyHandler(button));
    }

    this.addValueChangedHandler(this.input);
  }

  createKeyHandler(button) {
    var object = this;

    return function (event) {
      return object.handleKey(button, event);
    }
  }

  createToggleButtonHandler(button) {
    var object = this;

    return function (event) {
      object.toggle(button);
      return true;
    }
  }

  focus() {
    this.buttons[0].element.focus();
  }

  focusNext(button) {
    var target = button.element.nextSibling;

    if (target !== null && target.tagName === "BUTTON")
      target.focus();
  }

  focusPrevious(button) {
    var target = button.element.previousSibling;

    if (target !== null && target.tagName === "BUTTON")
      target.focus();
  }

  getValue() {
    return this.input.value;
  }

  handleKey(button, event) {
    var result = false;

    if (event.code === "Space") {
      event.preventDefault();
    }
    else if (event.code === "ArrowLeft") {
      this.focusPrevious(button);
      event.preventDefault();
    }
    else if (event.code === "ArrowRight") {
      this.focusNext(button);
      event.preventDefault();
    }
    else if (event.code === "Home") {
      this.buttons[0].element.focus();
      event.preventDefault();
    }
    else if (event.code === "End") {
      this.buttons[this.buttons.length - 1].element.focus();
      event.preventDefault();
    }
    else if (event.ctrlKey && event.code === "KeyA") {
      this.selectAll();
      event.preventDefault();
    }

    return result;
  }

  initialize() {
    var query = new DomQuery(this.element);
    var buttons = query.getDescendants(WithTagName("BUTTON"));

    for (var button of buttons)
      this.buttons.push(new MultipleSelectButton(button));

    this.input = query.getDescendant(WithTagName("INPUT"));
  }

  recalculate() {
    var values = new Array();

    for (var button of this.buttons)
      if (button.selected)
        values.push(button.value);

    this.setValue(values.join(","));
  }

  selectAll() {
    for (var button of this.buttons)
      if (!button.selected)
        button.toggle();

    this.recalculate();
  }

  setValue(value) {
    this.input.value = value;

    this.input.dispatchEvent(new Event("input"));
    this.input.dispatchEvent(new Event("change"));
  }

  toggle(button) {
    button.toggle();
    this.recalculate();
  }
}

function MultipleSelectButton(element) {
  this.toggle = function () {
    this.selected = !this.selected;

    this.classSwitch.setStatus(this.selected);
    this.element.setAttribute("aria-selected", this.selected);
  }

  this.element = element;
  this.element.tabIndex = -1;

  this.value = this.element.dataset.Value;
  this.selected = element.classList.contains("Selected");

  this.classSwitch = new HtmlClassSwitch(this.element, "Selected");
  this.selected = this.classSwitch.getStatus();
}

interactivityRegistration.register("MultipleSelectButtons", function (element) { return new MultipleSelectButtons(element); });
