class SegmentedButton extends FormField {
  constructor(element) {
    super(element);

    if (this.mode === ControlMode.edit) {
      this.options = this.determineOptions();
      this.options[0].element.tabIndex = 0;

      this.attachHandlers();
    }
  }

  addEventListener(event, handler) {
    for (const option of this.options)
      option.input.addEventListener(event, handler);
  }

  attachHandlers() {
    for (const option of this.options) {
      option.input.addEventListener("click", this.createSelectHandler(option));
      option.element.addEventListener("keydown", this.createKeyHandler(option));

      this.addValueChangedHandler(option.input);

      option.element.addEventListener(
        "blur",
        (event) => {
          if (!this.options.some(option => option.element === event.relatedTarget) && event.relatedTarget !== null)
            this.validate();
        }
      );
    }
  }

  createKeyHandler(option) {
    return (event) => {
      return this.handleKey(option, event);
    }
  }

  createSelectHandler(option) {
    return (event) => {
      this.select(option);
    }
  }

  determineOptions() {
    const options = new Array();
    const query = new DomQuery(this.element);
    const elements = query.getDescendants(WithTagName("LABEL"));

    for (const element of elements)
      options.push(new SegmentedButtonElement(element));

    return options;
  }

  focus() {
    this.options[0].element.focus();
  }

  focusNext(option) {
    const target = option.element.nextSibling;

    if (target !== null && target.tagName === "LABEL")
      target.focus();
  }

  focusPrevious(option) {
    const target = option.element.previousSibling;

    if (target !== null && target.tagName === "LABEL")
      target.focus();
  }

  getValue() {
    let result = null;
    let index = 0;

    while (result === null && index < this.options.length) {
      const option = this.options[index];

      if (option.input.checked)
        result = option.value;
      else
        index++;
    }

    return result;
  }

  setValue(value) {
    for (const option of this.options) {
      if (value !== option.value)
        option.deselect();
      else if (!option.input.checked)
        option.select();
    }
  }

  handleKey(option, event) {
    if (event.code === "Space" || event.code === "Enter")
      this.handleKeyStroke(this.handleSelectKey, option, event);
    else if (event.code === "ArrowLeft")
      this.handleKeyStroke(this.focusPrevious, option, event);
    else if (event.code === "ArrowRight")
      this.handleKeyStroke(this.focusNext, option, event);
    else if (event.code === "Home")
      this.handleKeyStroke(this.handleHomeKey, option, event);
    else if (event.code === "End")
      this.handleKeyStroke(this.handleEndKey, option, event);
  }

  handleHomeKey(option) {
    this.options[0].element.focus();
  }

  handleEndKey(option) {
    this.options[this.options.length - 1].element.focus();
  }

  handleSelectKey(option) {
    this.select(option);
    option.input.dispatchEvent(new Event("change"));
  }

  handleKeyStroke(callback, option, event) {
    callback.call(this, option);
    event.preventDefault();
  }

  select(value) {
    for (const option of this.options)
      option.deselect();

    value.select();
  }
}

class SegmentedButtonElement {
  constructor(element) {
    this.element = element;
    this.element.tabIndex = -1;

    this.initialize();
  }

  deselect() {
    this.input.checked = false;
    this.element.classList.remove("Selected");
  }

  initialize() {
    this.input = new DomQuery(this.element).getChild(WithTagName("INPUT"));

    if (this.input.checked)
      this.element.classList.add("Selected");

    this.value = new ValueTextParser().parseText(this.input.value);
  }

  select() {
    this.input.checked = true;
    this.element.classList.add("Selected");
  }
}

interactivityRegistration.register("SegmentedButton", function (element) { return new SegmentedButton(element); });
