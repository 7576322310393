class TabPage extends WebPageComponentClass {
    constructor (element, tab, index) {
        super(element);

        this.index = index;
        this.tab = tab;
        
        this.page = element;
        this.page.component = this;

        this.determineElements();    
    }

    addEventHandlers(panel) {
        this.tab.tabIndex = 0;

        this.tab.addEventListener(
            "click", 
            (event) => {
                panel.selectTab(this.index, true);
            }
        );

        this.tab.addEventListener(
            "keydown",
            (event) => {
                this.handleKey(event, panel);
            }
        );

        this.selectedTabSwitch = new HtmlClassSwitch(this.tab, "Selected");
        this.selectedPageSwitch = new HtmlClassSwitch(this.page, "Selected");
    }

    handleKey(event, panel) {
        if (event.code === "Space") {
            panel.selectTab(this.index, true);
            event.stopHandling();
        }
        else if (event.code === "Home") {
            panel.selectTab(0, true);
            event.stopHandling();
        }
        else if (event.code === "End") {
            panel.selectTab(panel.tabPages.length - 1, true);
            event.stopHandling();
        }
        else if (event.code === "ArrowLeft") {
            panel.selectTab(this.index - 1, true);
            event.stopHandling();
        }
        else if (event.code === "ArrowRight") {
            panel.selectTab(this.index + 1, true);
            event.stopHandling();
        }
    }

    determineElements() {
        this.content = this.page.childNodes[0];
        this.count = new DomQuery(this.tab).getChild(WithClass("Count"));
    }

    handleEvent(event) {
        if (event instanceof CountChangedEvent) {
            if (event.count > 0)
                this.count.innerText = event.count;
            else
                this.count.innerText = "";

            event.handled = true;
        }
    }

    select(enabled, updateFocus) {
        this.selectedTabSwitch.setStatus(enabled);
        this.selectedPageSwitch.setStatus(enabled);

        if (enabled && updateFocus)
            this.tab.focus();
    }

    reload() {
        fetch(
            this.uri,
            {
                method: "GET"
            }
        )
            .then((response) => this.reloadFromResponse(response));
    }

    async reloadFromResponse(response) {
        interactivityRegistration.detach(this.page);
        this.page.innerHTML = await(response.text());
        this.content = this.page.childNodes[0];
        interactivityRegistration.attach(this.page);
    }

    get enabled() {
        return this.tab.dataset.Enabled === "true";
    }

    set enabled(value) {
        if (this.enabled !== value) {
            this.reload();

            this.tab.dataset.Enabled = JSON.stringify(value);
            this.page.dataset.Enabled = JSON.stringify(value);
        }
    }

    get identifier() {
        return this.tab.dataset.Identifier;
    }

    get uri() {
        return this.tab.dataset.Uri;
    }
}

class TabPanel extends WebPageComponentClass {
    constructor(element) {
        super(element);
    
        this.tabPages = new Array();
        this.determinePages();
        this.attachPageEventHandlers();
    }

    determinePages() {
        const query = new DomQuery(this.element);

        const tabElements = new DomQuery(query.getChild(WithClass("Tabs"))).getChildren(WithTagName("LI"));
        const pageElements = new DomQuery(query.getChild(WithClass("Pages"))).getChildren(WithTagName("DIV"));

        for (let index = 0; index < tabElements.length; index++)
            this.tabPages.push(new TabPage(pageElements[index], tabElements[index], index));
    };

    attachPageEventHandlers() {
         for (let index = 0; index < this.tabPages.length; index++)
            this.tabPages[index].addEventHandlers(this);
    }

    handleEvent(event) {
        if (event instanceof DataChangedEvent)
            this.reload();
    }

    reload() {
        fetch(
            this.uri, 
            {
                method: "GET",
            }
        )
            .then((response) => response.json())
            .then((data) => this.reloadFromResponse(data));
    }

    reloadFromResponse(data) {
        for (const tab of data) {
            const tabPage = this.tabPages.find((element) => { return element.identifier === tab.Identifier });
            tabPage.enabled = tab.Enabled;
        }
    }

    selectTab(newIndex, updateFocus) {
        const tabs = this.tabPages.filter((element) => element.enabled);

        if (newIndex < 0)
            newIndex = 0;
        else if (newIndex >= tabs.length)
            newIndex = tabs.length - 1;

        const formData = new FormData();
        formData.append("Active", tabs[newIndex].identifier);
            
        fetch(
            this.uri, 
            {
                method: "POST",
                body: formData
            }
        );

        for (let index = 0; index < tabs.length; index++) {
            let tabPage = tabs[index];
            tabPage.select(index === newIndex, updateFocus);
        }

        for (let index = 0; index < tabs.length; index++) {
            let tabPage = tabs[index];

            if (tabPage.content !== undefined && tabPage.content.component !== undefined)
                tabPage.content.component.handleEvent(new VisibilityChangedEvent(this));
        }
    }

    get uri() {
        return this.element.dataset.Uri;
    }
}

interactivityRegistration.register("TabPanel", function (element) { return new TabPanel(element); });
