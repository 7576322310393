class TabPage extends WebPageComponentClass {
  constructor(element, tab, index) {
    super(element);

    this.index = index;
    this.tab = tab;

    this.page = element;
    this.page.component = this;

    this.determineElements();
  }

  addEventHandlers(panel) {
    this.tab.tabIndex = 0;

    this.tab.addEventListener(
      "click",
      (event) => {
        event.preventDefault();
        panel.selectTab(this.index, true);
      }
    );

    this.tab.addEventListener(
      "keydown",
      (event) => {
        this.handleKey(event, panel);
      }
    );

    window.addEventListener(
      "hashchange",
      (event) => {
        if (window.location.hash.substring(1) === this.tab.id)
          panel.selectTab(this.index, true);
      }
    );

    this.selectedTabSwitch = new HtmlClassSwitch(this.tab, "Selected");
    this.selectedPageSwitch = new HtmlClassSwitch(this.page, "Selected");
  }

  handleKey(event, panel) {
    if (event.code === "Space") {
      panel.selectTab(this.index, true);
      event.stopHandling();
    }
    else if (event.code === "Home") {
      panel.selectTab(panel.getNextTab(-1), true);
      event.stopHandling();
    }
    else if (event.code === "End") {
      panel.selectTab(panel.getPreviousTab(panel.childComponents.length), true);
      event.stopHandling();
    }
    else if (event.code === "ArrowLeft") {
      panel.selectTab(panel.getPreviousTab(this.index), true);
      event.stopHandling();
    }
    else if (event.code === "ArrowRight") {
      panel.selectTab(panel.getNextTab(this.index), true);
      event.stopHandling();
    }
  }

  determineElements() {
    this.content = this.page.childNodes[0];
    this.count = new DomQuery(this.tab).getChild(WithClass("Count"));
  }

  handleEvent(event) {
    if (event instanceof CountChangedEvent) {
      if (event.count > 0)
        this.count.innerText = event.count;
      else
        this.count.innerText = "";

      event.handled = true;
    }
    else
      super.handleEvent(event);
  }

  select(enabled, updateFocus) {
    this.selectedTabSwitch.setStatus(enabled);
    this.selectedPageSwitch.setStatus(enabled);

    if (enabled) {
      window.history.replaceState(
        { url: window.location.href },
        null,
        "#" + this.tab.id
      );

      if (updateFocus)
        this.tab.focus();
    }
  }

  async validate() {
    await this.reload();
  }

  async reload() {
    await fetch(
      this.uri,
      {
        method: "GET"
      }
    )
      .then((response) => this.reloadFromResponse(response));
  }

  async reloadFromResponse(response) {
    interactivityRegistration.detach(this.content);
    this.page.innerHTML = await (response.text());
    this.content = this.page.childNodes[0];
    interactivityRegistration.attach(this.content);
  }

  get enabled() {
    return this.tab.dataset.Enabled === "true";
  }

  set enabled(value) {
    if (this.enabled !== value) {
      this.invalidate();

      this.tab.dataset.Enabled = JSON.stringify(value);
      this.page.dataset.Enabled = JSON.stringify(value);
    }
  }

  get identifier() {
    return this.tab.dataset.Identifier;
  }

  get uri() {
    return this.tab.dataset.Uri;
  }
}

class TabPanel extends WebPageComponentClass {
  constructor(element) {
    super(element);

    this.determinePages();
    this.attachPageEventHandlers();

    const fragment = window.location.hash.substring(1);

    for (const page of this.childComponents)
      if (page.tab.id === fragment)
        this.selectTab(page.index, true);
  }

  determinePages() {
    const query = new DomQuery(this.element);

    const tabElements = new DomQuery(query.getChild(WithClass("Tabs"))).getChildren(WithTagName("LI"));
    const pageElements = new DomQuery(query.getChild(WithClass("Pages"))).getChildren(WithTagName("DIV"));

    for (let index = 0; index < tabElements.length; index++) {
      const page = new TabPage(pageElements[index], tabElements[index], index);
      page.parentComponent = this;

      this.childComponents.push(page);
    }
  };

  attachPageEventHandlers() {
    for (let index = 0; index < this.childComponents.length; index++)
      this.childComponents[index].addEventHandlers(this);
  }

  handleEvent(event) {
    if (event instanceof DataChangedEvent)
      this.invalidate();
    else
      super.handleEvent(event);
  }

  async validate() {
    await this.reload();
  }

  async reload() {
    await fetch(
      this.uri,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => this.reloadFromResponse(data));
  }

  reloadFromResponse(data) {
    for (const tab of data) {
      const tabPage = this.childComponents.find((element) => { return element.identifier === tab.Identifier });
      tabPage.enabled = tab.Enabled;
    }
  }

  getNextTab(index) {
    const result = this.childComponents.slice(index + 1).findIndex(tab => tab.enabled);
    return result === -1 ? index : result + index + 1;
  }

  getPreviousTab(index) {
    const result = this.childComponents.slice(0, index).findLastIndex(tab => tab.enabled);
    return result === -1 ? index : result;
  }

  selectTab(index, updateFocus) {
    if (index >= 0 && index < this.childComponents.length) {
      if (this.childComponents[index].enabled) {
        const selected = this.childComponents[index];

        const formData = new FormData();
        formData.append("Active", selected.identifier);

        fetch(
          this.uri,
          {
            method: "POST",
            body: formData
          }
        );

        for (const tab of this.childComponents) {
          if (tab.enabled) {
            tab.select(tab === selected, updateFocus);

            if (tab.content !== undefined && tab.content.component !== undefined)
              tab.content.component.handleEvent(new VisibilityChangedEvent(this));
          }
        }
      }
    }
  }

  get uri() {
    return this.element.dataset.Uri;
  }
}

interactivityRegistration.register("TabPanel", function (element) { return new TabPanel(element); });
