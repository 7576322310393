class FormField extends WebPageComponentClass {
  constructor(element) {
    super(element);
  }

  addValueChangedHandler(source) {
    source.addEventListener(
      "change",
      (event) => {
        this.validate();
      }
    );
  }

  addBlurHandler(source) {
    source.addEventListener(
      "blur",
      (event) => {
        if (event.relatedTarget !== null && !this.element.contains(event.relatedTarget))
          this.validate();
      }
    );
  }

  addProblem(message) {
    this.parentComponent.addProblem(this, message);
  }

  clearProblems() {
    this.parentComponent.clearProblems(this);
  }

  checkInput() {
      const value = this.getValue();

      if (value === null && !this.optional)
        this.addProblem(this.element.dataset.EmptyValueMessage);
  }

  validate() {
    if (typeof this.parentComponent.addProblem === "function") {
      this.clearProblems();
      this.checkInput();
    }
  }

  getName() {
    return this.name;
  }

  getValue() {
    return null;
  }

  setValue(value) {
  }

  get name() {
    return this.element.dataset.Name;
  }

  get optional() {
    return this.element.dataset.Optional === "true";
  }

  get value() {
    return this.getValue();
  }

  set value(value) {
    this.setValue(value);
  }
}

class DefaultFormField extends FormField {
  constructor(element) {
    super(element);

    this.input = null;
    this.focused = new HtmlClassSwitch(this.element, "Focus");
  }

  bind() {
    if (this.input !== null) {
      this.input.addEventListener("focus", (event) => { this.focused.setStatus(true); });
      this.input.addEventListener("blur", (event) => { this.focused.setStatus(false); });
    }
  }

  addEventListener(event, handler) {
    if (this.input !== null)
      this.input.addEventListener(event, handler);
  }

  focus() {
    if (this.input !== null)
      this.input.focus();
  }

  getValue() {
    return this.input.value !== "" ? this.input.value : null;
  }

  setValue(value) {
    this.input.value = value;
  }
}
