var TableRenderScope = new Enumeration(["Toolbar", "Layout", "Header", "Rows", "Footer", "Complete", "Navigation"]);

function ColumnMapping(identifier, index, span) {
  this.identifier = identifier;
  this.index = index;
  this.span = span;
}

class CommandMessage extends XmlMessage {
  constructor() {
    super();

    this.commands = new Array();
  }

  toXml() {
    const result = this.commands.join("");
    return `<Commands>${result}</Commands>`;
  }
}

class RenderMessage extends XmlMessage {
  constructor(scopes) {
    super();

    this.scopes = scopes;
  }

  toXml() {
    let command = "<Render>"

    this.scopes.forEach(
      (scope) => {
        command = command + "<Scope Name=\"" + TableRenderScope.toText(scope) + "\"/>";
      }
    );

    command += "</Render>";
    return command;
  }
}

class CountChangedEvent {
  constructor(count) {
    this.count = count;
  }
}

class Table extends WebPageComponentClass {
  constructor(element) {
    super(element);

    this.element.tabIndex = "0";

    this.loadSettings(this.element);
    this.determineElements();
    this.initializeComponents();
    this.initializeCells();

    this.attachEventHandlers();
    this.attachNavigationHandlers();
    this.attachSearchHandler();
    this.attachMenuEventHandlers();
    this.attachViewHandlers();
    this.attachTableEventHandlers();
  }

  getTableElement() {
    return this.tableElement;
  }

  getRows() {
    return this.getTableElement().tBodies[0].rows;
  }

  getForm() {
    const form = this.tableElement.parentNode;

    if (form.tagName === "FORM")
      return form;
    else
      return null;
  }

  determineTableElement() {
    let parent = this.contents;

    if (this.useSiblingComponent)
      parent = new DomQuery(parent).getNthChild(WithTagName("FORM"), 0);

    return new DomQuery(parent).getChild(WithTagName("TABLE"));
  }

  columnClicked(columnIdentifier) {
    this.sendCommandRequest("<SortColumn Id=\"" + columnIdentifier + "\"/>");
  }

  updateSortColumn(element) {
    const columnIdentifier = element.getAttribute("ColumnIdentifier");
    const order = element.getAttribute("Order");

    for (const row of this.getColumnsRows()) {
      for (const cell of row.cells) {
        const id = cell.dataset.Identifier;
        const icon = new DomQuery(cell).getDescendant(WithClass("Sort"));

        if (icon !== null) {
          const classes = new HtmlClasses(icon);

          classes.remove("Ascending");
          classes.remove("Descending");

          if (id === columnIdentifier) {
            classes.add(order == 0 ? "Ascending" : "Descending");
          }
        }
      }
    }
  }

  getHeaderRowWithClass(className) {
    return Array.from(this.getTableElement().tHead.rows).find(row => nodeHasClass(row, className)) || null;
  }

  getHeaderRowsWithClass(className) {
    return Array.from(this.getTableElement().tHead.rows).filter(row => nodeHasClass(row, className));
  }

  getSearchRow() {
    return new DomQuery(this.toolbar.element).getDescendant(WithClass("Search"));
  }

  getSearchInput() {
    const row = this.getSearchRow();

    if (row !== null)
      return new DomQuery(row).getDescendant(WithTagName("INPUT"));
    else
      return null;
  }

  getFilterRows() {
    return this.getHeaderRowsWithClass("Filter");
  }

  getColumn(identifier) {
    const columnGroup = this.tableElement.firstChild;
    let column = null;

    for (const node of columnGroup.childNodes)
      if (node.dataset.Identifier === identifier)
        column = node;

    return column;
  }

  getColumnsRows() {
    return this.getHeaderRowsWithClass("Columns");
  }

  toggleFilter() {
    this.filterEnabled = !this.filterEnabled;
    this.sendCommandRequest("<Filter Visible=\"" + this.filterEnabled + "\"/>");
    this.updateFilterVisibility();
  }

  setView(view) {
    if (view !== this.element.dataset.View) {
      this.element.dataset.View = view;
      this.sendCommandRequest("<View Value=\"" + this.element.dataset.View + "\"/>");
    }
  }

  updateFilterVisibility() {
    const rows = this.getFilterRows();

    for (const row of rows)
      setNodeClassEnabled(row, "Enabled", this.filterEnabled);
  }

  toggleLayoutPanel() {
    this.layoutPanel.open();
  }

  scheduleFilterOperation(operation) {
    this.filterTimer.kill();
    this.filterTimer.schedule(operation);
  }

  searchTextChanged(textBox) {
    if (textBox.value !== this.filterText) {
      this.filterText = textBox.value;
      this.scheduleFilterOperation(() => this.sendCommandRequest("<Search Text=\"" + escapeXMLAttributeValue(textBox.value) + "\"/>"));
    }
  }

  sendCommandRequest(command, blocking = true) {
    let message = null;

    if (this.requestQueue.getSize() >= 2) {
      const request = this.requestQueue.getLast();

      if (request.message instanceof CommandMessage) {
        message = request.message;
        message.commands.push(command);

        request.blocking = request.blocking || blocking;
      }
    }

    if (message === null) {
      message = new CommandMessage();
      message.commands.push(command);

      this.requestQueue.queue(
        this.uri,
        "POST",
        message,
        null,
        async (response) => {
          if (response.ok)
            await this.handleHttpCommandResponse(response);
        },
        blocking
      );
    }
  }

  async handleHttpCommandResponse(response) {
    const contentType = response.headers.get("Content-Type");

    if (contentType.slice(0, 15) == "application/xml") {
      const responseText = await response.text();
      const xml = new DOMParser().parseFromString(responseText, "application/xml");

      if (xml.documentElement.hasAttribute("Location")) {
        const type = xml.documentElement.getAttribute("Type");
        const redirect = xml.documentElement.getAttribute("Location");

        if (type === "Download") {
          window.open(redirect);
          this.refresh();
        }
        else if (type === "Redirect") {
          if (!application.pageHandler.load(redirect))
            this.refresh();
        }
      }
      else {
        const query = new DomQuery(xml.documentElement);
        const cursorElement = query.getChild(WithTagName("Cursor"));

        if (cursorElement !== null) {
          this.navigation.update(cursorElement);
          this.fireCountChangedEvent();
        }

        const scopesElement = query.getChild(WithTagName("Scopes"));
        const scopeElements = new DomQuery(scopesElement).getChildren(WithTagName("Scope"));

        const scopes = new Array();

        for (const scope of scopeElements)
          scopes.push(TableRenderScope.fromText(scope.getAttribute("Name")));

        this.invalidateScopes(scopes);
      }
    }
    else if (contentType.slice(0, 9) === "text/html")
      this.handleHttpRenderResponse(response);
  }

  sendActionRequest(action, form, callback) {
    let formData;

    if (form !== null)
      formData = new FormData(form);
    else
      formData = new FormData();

    formData.append("Action", action);
    formData.append("Selection", this.createSelectionElement());

    this.requestQueue.queue(
      this.uri,
      "POST",
      null,
      formData,
      async (response) => {
        if (response.ok) {
          await this.handleHttpCommandResponse(response);

          this.fireDataChanged();
          application.toastBox.addMessage(new ToastMessage("Action submitted successfully", "Success"));
        }
        else if (response.status === 400) {
          this.toolbar.state = ToolbarState.Warning;
          await this.refreshFromResponse(response);
          this.fireDataChanged();
        }
        else
          this.requestQueue.handleErrorResponse(response);

        callback();
      }
    );
  }

  sendRenderRequest(scopes) {
    this.requestQueue.queue(
      this.uri,
      "POST",
      new RenderMessage(scopes),
      null,
      async (response) => await this.handleHttpRenderResponse(response)
    );
  }

  handleHttpRenderScopeResponse(element, scope) {
    if (scope === TableRenderScope.Toolbar)
      this.replaceToolbar(element.firstChild, true);
    else if (scope === TableRenderScope.Layout)
      this.replaceLayoutPanel(element.firstChild.firstChild);
    else if (scope === TableRenderScope.Header)
      this.replaceHeader(element.firstChild.tHead, element.firstChild.firstChild);
    else if (scope === TableRenderScope.Rows)
      this.replaceBody(element.firstChild.firstChild);
    else if (scope === TableRenderScope.Footer)
      this.replaceFooter(element.firstChild.tFoot);
    else if (scope === TableRenderScope.Navigation)
      this.replaceNavigation(element.firstChild)
    else if (scope === TableRenderScope.Complete) {
      const newElement = element.firstChild;
      const newToolbar = new DomQuery(newElement).getChild(WithClass("Toolbar"));
      const newLayoutPanel = new DomQuery(newElement).getChild(WithClass("Layout"));
      const newTable = new DomQuery(newElement).getChild(WithClass("Contents")).firstChild;

      this.loadSettings(newElement);

      this.replaceTable(newTable);
      this.replaceToolbar(newToolbar, false);
      this.replaceLayoutPanel(newLayoutPanel);
    }
  }

  async handleHttpRenderResponse(response) {
    const dummyElement = document.createElement("div");
    dummyElement.innerHTML = await response.text();

    for (const element of dummyElement.firstChild.childNodes) {
      const scope = TableRenderScope.fromText(element.className);
      this.handleHttpRenderScopeResponse(element, scope);
    }

    this.renderStatus = true;
    this.updateFilterVisibility();

    if (this.selectAll !== null)
      this.selectAll.recalculate(this);

    this.getSize();
  }

  invalidateScopes(scopes) {
    for (const scope of scopes)
      this.invalidScopes.add(scope);

    if (this.invalidScopes.size !== 0) {
      this.sendRenderRequest(new Set(this.invalidScopes));
      this.invalidScopes.clear();
    }
  }

  replaceHeader(header, columnGroup) {
    interactivityRegistration.detach(this.tableElement.tHead);
    this.tableElement.replaceChild(header, this.tableElement.tHead);
    this.tableElement.replaceChild(columnGroup, this.tableElement.childNodes[0])
    interactivityRegistration.attach(header);

    this.createSelector();

    this.attachTableEventHandlers();
    this.fireChangedEvent(this.tableElement);
  }

  replaceFooter(footer) {
    if (footer !== null)
      this.tableElement.replaceChild(footer, this.tableElement.tFoot);
  }

  replaceNavigation(navigation) {
    this.element.replaceChild(navigation, this.navigation.element);

    this.navigation = new Navigation(navigation);
    this.attachNavigationHandlers();
  }

  attachEventHandlers() {
    this.element.addEventListener(
      "keydown",
      (event) => {
        if (!this.tableElement.contains(event.target) && event.code === "ArrowDown") {
          const rows = this.getRows();

          if (rows.length > 0)
            rows[0].focus();

          event.stopHandling();
        }
        else if (event.code === "F5") {
          this.refresh();
          this.element.focus();
          event.stopHandling();
        }
        else if (event.altKey && event.shiftKey && event.code === "KeyX") {
          this.toggleExpertMode();
          event.preventDefault();
        }
      }
    );
  }

  attachNavigationHandlers() {
    if (this.navigation.nextPage !== null) {
      this.navigation.nextPage.addEventListener("click", (event) => this.moveCursor("Up"));
      this.navigation.previousPage.addEventListener("click", (event) => this.moveCursor("Down"));
    }
    else {
      this.navigation.loadRows.addEventListener("click", (event) => this.setPageSize(this.navigation.increasePageSize(this.navigation.size)));
    }
  }

  moveCursor(direction) {
    this.sendCommandRequest("<CursorMove Direction=\"" + direction + "\"/>");
  }

  getSize() {
    this.sendCommandRequest("<Size/>", false);
  }

  setPageSize(count) {
    this.sendCommandRequest("<Cursor PageSize=\"" + count + "\"/>");
  }

  replaceBody(body) {
    interactivityRegistration.detach(this.tableElement.tBodies[0]);
    this.tableElement.replaceChild(body, this.tableElement.tBodies[0]);
    interactivityRegistration.attach(body);

    this.initializeCells();
    this.attachRowEventHandlers();
    this.fireChangedEvent(this.tableElement);
  }

  replaceTable(table) {
    interactivityRegistration.detach(this.contents.firstChild);
    this.contents.replaceChild(table, this.contents.firstChild);
    this.tableElement = this.determineTableElement();

    interactivityRegistration.attach(table);

    this.initializeCells();
    this.attachRowEventHandlers();
    this.createSelector();
    this.attachTableEventHandlers();
    this.fireChangedEvent(this.tableElement);
  }

  replaceToolbar(toolbar, persistForm) {
    this.toolbar.replace(toolbar, persistForm);

    if (this.toolbar.hasActionEnabled())
      this.element.classList.add("ActionEnabled");
    else
      this.element.classList.remove("ActionEnabled");

    this.attachMenuEventHandlers();
    this.attachViewHandlers();
  }

  replaceLayoutPanel(layoutPanel) {
    interactivityRegistration.detach(this.layoutPanel.element);
    this.element.replaceChild(layoutPanel, this.layoutPanel.element);
    interactivityRegistration.attach(layoutPanel);
    this.layoutPanel = new LayoutPanel(layoutPanel, this);
  }

  fireChangedEvent(newTable) {
    if (this.onChanged !== null)
      this.onChanged(newTable);
  }

  fireCountChangedEvent() {
    distributeEventUpHierarchy(new CountChangedEvent(this.navigation.total), this);
  }

  selectRow(checkBox) {
    const tableRow = new DomQuery(checkBox).getAncestor(WithTagName("TR"));
    setNodeClassEnabled(tableRow, "selectedRow", checkBox.checked);
  }

  selectAllRows(checked) {
    this.selectRange(0, this.getRows().length - 1, checked);
  }

  selectRange(low, high, checked) {
    const rows = this.getRows();

    for (let index = low; index <= high; index++) {
      const row = rows[index];
      const cell = row.cells[0];
      const checkBoxToChange = cell.getElementsByTagName("input")[0];

      if (checkBoxToChange != null && checkBoxToChange.checked !== checked && !checkBoxToChange.disabled) {
        checkBoxToChange.checked = checked;
        this.selectRow(checkBoxToChange);
      }
    }
  }

  createRowHotHandler(row, flag) {
    return function (event) {
      setHot(row, event, flag);
    };
  }

  createRowClickedHandler(row, uri) {
    return function (event) {
      const source = getEvent(event).getSource();

      if (shouldHandleMouseClick(row, source))
        openUrl(event, uri);
    };
  }

  attachRowEventHandlers() {
    for (const row of this.getRows()) {
      const component = new TableRow(row, this);

      if (component.uri !== null) {
        row.addEventListener("mouseover", this.createRowHotHandler(row, true));
        row.addEventListener("mouseout", this.createRowHotHandler(row, false));
        row.addEventListener("click", this.createRowClickedHandler(row, component.uri));
      }
    }
  }

  refresh() {
    this.invalidateScopes([TableRenderScope.Toolbar, TableRenderScope.Header, TableRenderScope.Rows, TableRenderScope.Footer, TableRenderScope.Navigation]);
  }

  refreshFromResponse(http) {
    this.handleHttpRenderResponse(http);
  }

  attachColumnSortHandlers() {
    let totalSpan = 0;

    for (const row of this.getColumnsRows()) {
      for (const cell of row.cells) {
        const classes = new HtmlClasses(cell);

        if (classes.contains("Column")) {
          const columnIdentifier = cell.dataset.Identifier;
          classes.add("Action");

          cell.addEventListener(
            "click",
            (event) => {
              this.columnClicked(columnIdentifier);
            }
          );

          this.columnIdentifierIndexMapping[columnIdentifier] = new ColumnMapping(columnIdentifier, totalSpan, cell.colSpan);
          totalSpan += cell.colSpan;
        }
      }
    }
  }

  resizeColumn(columnIdentifier, delta) {
    const column = this.getColumn(columnIdentifier);

    const reference = document.createElement("span");
    reference.innerHTML = "0";
    reference.style.visibility = "hidden";
    reference.style.fontSize = window.getComputedStyle(this.tableElement.tBodies[0]).fontSize;
    reference.style.fontWeight = "bold";
    reference.style.display = "block"
    reference.style.width = "min-content"

    document.body.appendChild(reference);
    const ratio = parseFloat(window.getComputedStyle(reference).width);
    document.body.removeChild(reference);

    const width = Math.max(parseFloat(column.style.width) + delta / ratio, 6);
    column.style.width = width + "ch";
  }

  commitColumnSize(columnIdentifier) {
    const column = this.getColumn(columnIdentifier);
    const width = parseFloat(column.style.width);
    const command = "<Resize Id=\"" + columnIdentifier + "\" Width=\"" + width + "\"/>";

    this.sendCommandRequest(command);
  }

  startResize() {
    this.tableElement.style.userSelect = "none";
  }

  finishResize() {
    this.tableElement.style.userSelect = "";
  }

  attachColumnResizeHandler(cell) {
    let pageX, column;

    cell.addEventListener(
      "mousedown",
      (event) => {
        pageX = event.pageX;
        column = event.target;

        this.startResize();
        cell.classList.add("Dragging");
      }
    );

    document.addEventListener(
      "mousemove",
      (event) => {
        if (column !== undefined) {
          if (pageX !== undefined)
            this.resizeColumn(column.dataset.Identifier, event.pageX - pageX);

          pageX = event.pageX;
        }
      }
    );

    document.addEventListener(
      "mouseup",
      (event) => {
        if (column !== undefined) {
          this.commitColumnSize(column.dataset.Identifier);
          event.stopPropagation();

          cell.classList.remove("Dragging");
          this.finishResize();
        }

        pageX = undefined;
        column = undefined;
      }
    );

    cell.onclick = (event) => {
      event.stopPropagation();
    };
  }

  attachColumnResizeHandlers() {
    for (const row of this.getColumnsRows()) {
      for (const cell of row.cells) {
        const resizer = new DomQuery(cell).getDescendant(WithClass("Resizer"));

        if (resizer !== null)
          this.attachColumnResizeHandler(resizer);
      }
    }
  }

  createColumnFilterHandler() {
    return () => {
      this.scheduleFilterOperation(
        () => {
          let command = "";
          let filters = 0;

          for (const field of this.filters) {
            const expression = field.getFilterText();
            const mode = field.getFilterMode();

            if (field.initialFilterText !== expression || field.initialFilterMode !== mode)
              command = command + "<FilterColumn Id=\"" + field.getId() + "\" Mode=\"" + escapeXMLAttributeValue(mode) + "\" Text=\"" + escapeXMLAttributeValue(expression) + "\"/>";

            field.initialFilterText = field.getFilterText();
            field.initialFilterMode = field.getFilterMode();

            const rows = this.getColumnsRows();

            for (const row of rows) {
              for (const cell of row.cells) {
                if (cell.dataset.Identifier === field.getId()) {
                  const icon = new DomQuery(cell).getDescendant(WithClass("Filter"));

                  if (icon !== null) {
                    const classes = new HtmlClasses(icon);

                    if (expression.length > 0) {
                      classes.add("Value");
                      filters++;
                    }
                    else
                      classes.remove("Value");
                  }
                }
              }
            }
          }

          const toggleFilter = new DomQuery(this.element).getDescendant(WithClass("ToggleFilter"));

          if (filters > 0)
            toggleFilter.childNodes[0].innerText = filters;
          else
            toggleFilter.childNodes[0].innerText = "";

          this.sendCommandRequest(command);
        }
      );
    }
  }

  attachSearchHandler() {
    const searchInput = this.getSearchInput();

    if (searchInput !== null) {
      this.filterText = searchInput.value;
      searchInput.addEventListener("input", (event) => this.searchTextChanged(searchInput))
    }
  }

  attachColumnFilterHandlers() {
    this.filters = new Array();

    for (const row of this.getFilterRows()) {
      for (const cell of row.cells) {
        const classes = new HtmlClasses(cell);
        const query = new DomQuery(cell);

        if (classes.contains("Column")) {
          const element = query.getDescendant(WithClass("Box"));
          const id = cell.dataset.Identifier;

          if (element !== null)
            this.filters.push(new FilterField(element, this.createColumnFilterHandler(), id));
        }
      }
    }
  }

  attachMenuEventHandlers() {
    this.attachToolbarButtonEventHandler("ToggleFilter", (event) => { this.toggleFilter(); });
    this.attachToolbarButtonEventHandler("Refresh", (event) => { this.refresh(); });
    this.attachToolbarButtonEventHandler("Layout", (event) => { this.toggleLayoutPanel(); });
  }

  toggleAction(action) {
    this.element.classList.add("ActionEnabled");
    this.sendCommandRequest(this.createSelectionElement() + "<Action Name=\"" + action + "\"/>");
  }

  attachViewHandlers() {
    const view = new DomQuery(this.element).getDescendant(WithClass("View"));
    const auto = new DomQuery(view).getChild(WithClass("Auto"));
    const list = new DomQuery(view).getChild(WithClass("List"));
    const columns = new DomQuery(view).getChild(WithClass("Columns"));
    const tiles = new DomQuery(view).getChild(WithClass("Tiles"));

    auto.addEventListener("click", (event) => this.setView("Auto"));
    list.addEventListener("click", (event) => this.setView("List"));
    columns.addEventListener("click", (event) => this.setView("Columns"));
    tiles.addEventListener("click", (event) => this.setView("Tiles"));
  }

  attachToolbarButtonEventHandler(action, handler) {
    const query = new DomQuery(this.element);
    const item = query.getDescendant(WithClass(action));

    if (item !== null)
      item.addEventListener("click", handler);
  }

  attachTableEventHandlers() {
    this.attachColumnSortHandlers();
    this.attachColumnFilterHandlers();
    this.attachColumnResizeHandlers();

    if (this.selectAll !== null)
      this.selectAll.checkBox.addEventListener(
        "change",
        (event) => {
          this.selectAllRows(this.selectAll.checkBox.getValue());
          this.fireSelectionChangedEvent();
        }
      );

    this.onSelectionChanged = () => {
      this.sendCommandRequest(this.createSelectionElement());
    }
  }

  createSelectionElement() {
    let result = "<Selection>"
    const selection = this.getSelectedRows();

    for (const row of selection)
      result = result + "<Row>" + escapeXMLCharacterData(row) + "</Row>";

    result = result + "</Selection>";
    return result;
  }

  handleInitialRenderTime() {
    if (this.renderTime === "Immediate") {
      this.renderStatus = true;
      this.getSize();
    }
    else if (this.renderTime === "Deferred")
      this.refresh();
  }

  assureRendered() {
    if (!this.renderStatus)
      this.refresh();
  }

  getSelectedRows() {
    const rows = this.getRows();
    const result = new Array();

    for (const row of rows)
      if (nodeHasClass(row, "selectedRow"))
        result.push(row.dataset.Identifier);

    return result;
  }

  getSelectedRowCount() {
    const rows = this.getRows();
    let count = 0;

    for (const row of rows)
      if (nodeHasClass(row, "selectedRow"))
        count++;

    return count;
  }

  getRowCount() {
    const rows = this.getRows();
    let count = 0;

    for (const row of rows) {
      const cell = row.cells[0];
      const checkBox = cell.getElementsByTagName("input")[0];

      if (checkBox != null && !checkBox.disabled)
        count++;
    }

    return count;
  }

  createSelector() {
    this.selectAll = null;

    if (this.rowSelectorEnabled) {
      const element =
        new DomPathQuery(this.tableElement)
          .getChild(WithTagName("THEAD"))
          .getChild(WithClass("Columns"))
          .getChild(WithClass("rowSelector"))
          .getChild(function (node) { return node.component !== undefined && node.component instanceof CheckBoxField })
          .getElement();

      if (element !== null) {
        this.selectAll = new Selector(element);
        this.selectAll.recalculate(this);
      }
    }
  }

  handleEvent(event) {
    if (event instanceof DataChangedEvent && this.refreshMode === RefreshMode.ForSiblings)
      this.invalidate();
  }

  async validate() {
    this.invalidateScopes([TableRenderScope.Complete]);
  }

  loadSettings(element) {
    this.filterEnabled = element.dataset.FilterEnabled === "true";
    this.rowSelectorEnabled = element.dataset.RowSelectorEnabled === "true";
    this.useSiblingComponent = element.dataset.UseSiblingComponent === "true";
    this.renderTime = element.dataset.RenderTime;
    this.uri = element.dataset.Uri;
    this.view = element.dataset.View;

    this.element.tabIndex = "0";
    this.renderStatus = false;
    this.lastSelected = null;
  }

  initializeComponents() {
    this.updating = new HtmlClassSwitch(this.element, "Updating");
    this.busy = new HtmlClassSwitch(this.element, "Busy");
    this.expertMode = new HtmlClassSwitch(this.element, "Expert");

    this.requestQueue = new RequestQueue();
    this.requestQueue.listeners.push((event) => {
      if (event instanceof QueueSizeChangedEvent) {
        this.updating.setStatus(event.size > 0);
        this.busy.setStatus(this.requestQueue.requests.find((request) => { return request.blocking; }) !== undefined);
      }
    });

    this.invalidScopes = new Set();
    this.columnIdentifierIndexMapping = new Object();
    this.onChanged = null;

    this.filterTimer = new Timer(250);
    this.filterText = "";
    this.updateFilterVisibility();

    this.attachRowEventHandlers();
    this.createSelector();
  }

  initializeCells() {
    const header = this.getColumnsRows()[0];
    let columnIndex = 0;

    for (const column of header.cells) {
      const caption = new DomQuery(column).getDescendant(WithClass("Cell"));

      for (const row of this.getRows()) {
        if (caption !== null) {
          const node = caption.cloneNode(true);
          row.cells[columnIndex].prepend(node);
        }
      }

      columnIndex += column.colSpan;
    }

    for (const row of this.getRows()) {
      const subs = new DomQuery(row).getChildren(WithClass("Sub"));

      for (const sub of subs) {
        const field = sub.childNodes[sub.childNodes.length - 2];

        if (field.nodeType === Node.ELEMENT_NODE) {
          if ("Value" in field.dataset) {
            if (field.dataset.Value.length === 0)
              sub.classList.add("Empty");
          }
          else if (field.innerText.length === 0)
            sub.classList.add("Empty");
        }
        else if (field.nodeType === Node.TEXT_NODE && field.textContent.length === 0)
          sub.classList.add("Empty");
      }

      const last = subs.findLast((element) => !element.classList.contains("Empty"));

      if (last !== undefined)
        last.classList.add("Last");
    }
  }

  determineElements() {
    this.contents = new DomQuery(this.element).getChild(WithClass("Contents"));
    this.tableElement = this.determineTableElement();

    this.toolbar = new Toolbar(new DomQuery(this.element).getChild(WithClass("Toolbar")));
    this.toolbar.parentComponent = this;

    this.navigation = new Navigation(new DomQuery(this.element).getChild(WithClass("NavigationBar")));
    this.layoutPanel = new LayoutPanel(new DomQuery(this.element).getChild(WithClass("Layout")), this);
  }

  bind() {
    this.handleInitialRenderTime();
  }

  toggleExpertMode() {
    this.expertMode.toggle();
  }
}

function LayoutPanel(element, table) {
  WebPageComponent.call(this, element);

  this.createColumnDragStartHandler = function (column) {
    return (event) => {
      this.sourceColumn = column;
      this.sourceIndex = this.columns.indexOf(column);

      column.classList.add("Moving");
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.setData("text/plain", "move-column");
    };
  }

  this.createColumnDragEndHandler = function (column) {
    return (event) => {
      if (!this.dropped) {
        if (this.columns.indexOf(column) < this.sourceIndex)
          this.moveColumn(this.sourceIndex + 1);
        else
          this.moveColumn(this.sourceIndex);
      }

      this.sourceColumn.classList.remove("Moving");
      this.sourceColumn = null;
      this.dropped = false;
    };
  }

  this.createOnDragOverHandler = function () {
    return (event) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";

      this.moveColumn(this.determineTargetIndex(event.target.offsetTop + event.offsetY));
    };
  }

  this.createOnDragLeaveHandler = function (column) {
    return (event) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    };
  }

  this.createOnDragExitHandler = function (column) {
    return (event) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    };
  }

  this.createOnDragEnterHandler = function (column) {
    return (event) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    };
  }

  this.createOnDropHandler = function (column) {
    return (event) => {
      event.preventDefault();
      this.dropColumn();
    };
  }

  this.getColumnMiddle = function (column) {
    return column.offsetTop + column.offsetHeight / 2;
  }

  this.determineTargetIndex = function (offsetY) {
    let result = 0;

    while (result < this.columns.length && this.getColumnMiddle(this.columns[result]) < offsetY)
      result++;

    return result;
  }

  this.moveColumn = function (targetIndex) {
    const sourceIndex = this.columns.indexOf(this.sourceColumn);

    if (targetIndex !== sourceIndex && targetIndex !== sourceIndex + 1) {
      const targetColumn = this.columns[targetIndex];

      this.projection.insertBefore(this.sourceColumn, targetColumn);
      this.columns = new DomQuery(this.projection).getChildren(WithClass("Column"));
    }
  }

  this.dropColumn = function () {
    this.updateProjection();
    this.dropped = true;
  }

  this.updateProjection = function () {
    this.table.sendCommandRequest("<Projection>" + this.getCurrentProjectionAsXml() + "</Projection>");
  }

  this.attachEventHandlers = function () {
    this.projection = new DomQuery(this.element).getDescendant(WithClass("Projection"));
    this.projection.ondragover = this.createOnDragOverHandler();
    this.projection.ondragleave = this.createOnDragLeaveHandler();
    this.projection.ondragenter = this.createOnDragEnterHandler();
    this.projection.ondragexit = this.createOnDragExitHandler();
    this.projection.ondrop = this.createOnDropHandler();

    this.columns = new DomQuery(this.projection).getChildren(WithClass("Column"));

    for (const column of this.columns) {
      const checkBox = column.childNodes[0].component;
      checkBox.addEventListener(
        "change",
        (event) => {
          this.updateProjection();
        }
      );

      column.ondragstart = this.createColumnDragStartHandler(column);
      column.ondragend = this.createColumnDragEndHandler(column);
      column.draggable = true;
    }
  }

  this.handleLayoutDropDownChanged = function () {
    this.table.sendCommandRequest("<SelectLayout Name=\"" + escapeXMLAttributeValue(this.layoutDropDown.getValue()) + "\"/>");
    this.close();
  }

  this.handleShareButtonClicked = function () {
    const layoutName = this.layoutName.getValue();
    this.table.sendCommandRequest("<ShareLayout Name=\"" + escapeXMLAttributeValue(layoutName) + "\"/>");
    this.close();
  }

  this.createOnLayoutDropDownChangedHandler = function () {
    return (event) => {
      this.handleLayoutDropDownChanged();
    }
  }

  this.createOnShareButtonClickHandler = function () {
    return (event) => {
      this.handleShareButtonClicked();
    }
  }

  this.bind = function () {
    this.closeButton = new DomQuery(this.element).getDescendant(WithClass("Close"));
    this.closeButton.addEventListener("click", () => { this.close() });

    this.predefined = new DomQuery(this.element).getDescendant(WithClass("Predefined"));

    this.layoutDropDown = this.getDescendant(new ComponentWithName("Layout"));
    this.layoutDropDown.addEventListener("change", this.createOnLayoutDropDownChangedHandler());

    this.layoutName = this.getDescendant(new ComponentWithName("Name"));
    this.shareButton = new DomQuery(this.element).getDescendant(WithClass("Share"));

    if (this.shareButton !== null) {
      this.shareButton.onclick = this.createOnShareButtonClickHandler();
    }
  }

  this.getCurrentProjectionAsXml = function () {
    let result = "";

    for (const column of this.columns)
      result += "<Column Index=\"" + column.dataset.Index + "\" Visible=\"" + column.childNodes[0].component.getValue() + "\"/>";

    return result;
  }

  this.open = function () {
    this.visible.setStatus(true);

    this.clickOutsideListener = connectClickOutsideListener(
      this.element.childNodes[0],
      () => {
        this.close();
      }
    );
  }

  this.close = function () {
    this.visible.setStatus(false);
    removeClickOutsideListener(this.clickOutsideListener);
  }

  this.table = table;
  this.visible = new HtmlClassSwitch(element, "Visible");
  this.sourceColumn = null;
  this.dropped = false;
  this.projection = null;
  this.predefined = null;
  this.layoutDropDown = null;
  this.shareButton = null;
  this.confirmationWindow = null;

  this.attachEventHandlers();
  this.bind();
}

function Navigation(element) {
  this.determineElements = function () {
    const query = new DomQuery(this.element);

    this.information = query.getChild(WithClass("Information"));
    this.loadRows = query.getChild(WithClass("LoadRows"));
    this.nextPage = query.getChild(WithClass("NextPage"));
    this.previousPage = query.getChild(WithClass("PreviousPage"));

    this.determineButtonStates();
  }

  this.determineButtonStates = function () {
    if (this.loadRows !== null)
      this.loadRows.disabled = this.total === null || this.size >= this.total || this.size === 1000;
    else {
      this.nextPage.disabled = this.total === null || this.offset + this.size >= this.total;
      this.previousPage.disabled = this.offset === 0;
    }
  }

  this.increasePageSize = function (pageSize) {
    if (pageSize === this.initialPageSize)
      return 250;
    else if (pageSize < 1000)
      return pageSize + 250;
    else
      return pageSize;
  }

  this.update = function (xml) {
    this.information.innerText = xml.getAttribute("Label");
    this.offset = xml.getAttribute("Offset");
    this.initialPageSize = xml.getAttribute("InitialPageSize");
    this.size = xml.getAttribute("PageSize");

    if (xml.hasAttribute("Total"))
      this.total = xml.getAttribute("Total");

    this.determineButtonStates();
  }

  Object.defineProperty(this, "initialPageSize", {
    get: function () {
      return parseInt(this.information.getAttribute("data--initial-page-size"));
    },
    set: function (size) {
      this.information.setAttribute("data--initial-page-size", size);
    }
  });

  Object.defineProperty(this, "offset", {
    get: function () {
      return parseInt(this.information.getAttribute("data--offset"));
    },
    set: function (offset) {
      this.information.setAttribute("data--offset", offset);
    }
  });

  Object.defineProperty(this, "size", {
    get: function () {
      return parseInt(this.information.getAttribute("data--page-size"));
    },
    set: function (size) {
      this.information.setAttribute("data--page-size", size);
    }
  });

  Object.defineProperty(this, "total", {
    get: function () {
      if (this.information.hasAttribute("data--total"))
        return parseInt(this.information.getAttribute("data--total"));
      else
        return null;
    },
    set: function (total) {
      this.information.setAttribute("data--total", total);
    }
  });

  this.element = element;
  this.determineElements();
}

class Selector extends WebPageComponentClass {
  constructor(element) {
    super(element);

    this.checkBox = element.component;

    this.count = document.createElement("span");
    this.count.classList.add("Count");

    this.element.parentNode.appendChild(this.count);
  }

  recalculate(table) {
    const rowCount = table.getRowCount();
    const selected = table.getSelectedRowCount();

    if (selected > 0)
      this.count.innerHTML = selected;
    else
      this.count.innerHTML = "";

    this.checkBox.input.indeterminate = false;

    if (rowCount == 0 || selected == 0)
      this.checkBox.input.checked = false;
    else if (selected < rowCount)
      this.checkBox.input.indeterminate = true;
    else
      this.checkBox.input.checked = true;
  }
}

function GroupingTable(element) {
  WebPageComponent.call(this, element);

  this.expandOrCollapse = function (element) {
    var classes = new HtmlClasses(element);

    classes.toggle("collapsed");
    classes.toggle("expanded");
  }

  this.createOnClickHandler = function (item) {
    return () => {
      const body = new DomQuery(item).getAncestor(WithClass("expandable"));
      this.expandOrCollapse(body);
    };
  }

  this.attachItemHandlers = function (element) {
    element.onclick = this.createOnClickHandler(element);
  }

  this.attachItemsHandlers = function (elements) {
    for (var i = 0; i < elements.length; i++)
      this.attachItemHandlers(new DomQuery(elements[i]).getChild(WithClass("category")));
  }

  this.determineElements = function () {
    this.attachItemsHandlers(new DomQuery(this.element).getDescendants(WithClass("expandable")));
  }

  this.element = element;
  this.determineElements();
}

function MasterTableRow(element) {
  WebPageComponent.call(this, element);

  this.expandOrCollapse = function (element, event) {
    var source = event.getSource();

    if (shouldHandleMouseClick(element, source)) {
      var classes = new HtmlClasses(this.detailsElement);
      classes.toggle("Collapsed");
      classes.toggle("Expanded");
    }
  }

  this.createOnClickHandler = function (item) {
    return (event) => {
      this.expandOrCollapse(item, getEvent(event));
    };
  }

  this.attachItemHandler = function (element) {
    element.onclick = this.createOnClickHandler(element);
  }

  this.determineElements = function () {
    this.attachItemHandler(this.element);
  }

  this.element = element;
  this.detailsElement = element.nextSibling;
  this.determineElements();
}

function FilterField(element, handler, id) {
  WebPageComponent.call(this, element);

  this.attachEventHandlers = function () {
    var component = this;

    this.element.onkeydown = function (event) {
    };

    this.target.addEventListener("input", function (event) { component.handler(); });
    this.button.onclick = function (event) { component.toggleForm(event); };

    for (var field of this.fields)
      field.onchange = function (event) { component.handler(); };
  }

  this.determineElements = function () {
    var component = this;
    component.fields = new DomQuery(this.form).getDescendants(WithTagName("INPUT"));
  }

  this.getId = function () {
    return this.id;
  }

  this.getFilterText = function () {
    const value = this.target.getValue();
    return value !== null ? value : "";
  }

  this.getFilterMode = function () {
    for (var field of this.fields) {
      if (field.checked)
        this.filterMode = field.getAttribute("value");
    }

    return this.filterMode;
  }

  this.toggleForm = function (event) {
    this.formState.toggle();

    if (!this.form.classList.contains("Collapsed")) {
      this.clickOutsideListener = connectClickOutsideListener(this.form, (event) => { this.toggleForm(event); });
      this.fields[0].focus();
    }
    else if (this.clickOutsideListener != null)
      removeClickOutsideListener(this.clickOutsideListener);

    event.stopPropagation();
  }

  this.handler = handler;
  this.id = id;

  this.button = element.childNodes[0];
  this.button.tabIndex = -1;

  this.target = element.childNodes[1].component;
  this.form = element.childNodes[2];

  this.fields = null;

  this.filterMode = null;
  this.formState = new HtmlClassSwitch(this.form, "Collapsed");
  this.clickOutsideHandler = null;

  this.determineElements();
  this.attachEventHandlers();

  this.initialFilterText = this.getFilterText();
  this.initialFilterMode = this.getFilterMode();
}

class QuickActions {
  constructor(element, row) {
    this.element = element;
    this.row = row;

    this.initialize();
  }

  initialize() {
    const query = new DomQuery(this.element);

    this.button = query.getChild(WithTagName("BUTTON"));
    this.button.addEventListener(
      "click",
      (event) => {
        this.toggle();
        event.stopPropagation();
      }
    );

    this.actions = query.getDescendants(WithClass("QuickAction"));
    this.expand = new HtmlClassSwitch(this.element, "Expanded");
    this.rowSwitch = new HtmlClassSwitch(this.row, "QuickActionSelected");
  }

  toggle() {
    this.expand.toggle();
    this.rowSwitch.toggle();

    if (this.expand.getStatus()) {
      this.clickOutsideListener = connectClickOutsideListener(
        this.element,
        () => {
          this.toggle();
        }
      );
    }
    else
      removeClickOutsideListener(this.clickOutsideListener);
  }
}

interactivityRegistration.register("Table", function (element) { return new Table(element); });
interactivityRegistration.register("grouping", function (element) { return new GroupingTable(element); });
interactivityRegistration.register("Master", function (element) { return new MasterTableRow(element); });
